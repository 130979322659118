.lackyJetBack{
    background-image: url('../images/lacky/background2.png');
    width: 100%;
    height: 100%;  
    display: flex; 
  flex-direction: column; 
  justify-content: center; 
  align-items: center; 
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  
}

.timestartUp{
  font-size:15px;
  font-weight: 600;
  color: white;
  margin-top: 1%;
  font-family: 'GilroyLight';
}

.timestartDown{
  font-size:15px;
  font-weight: 600;
  color: white;
  margin-top: 1%;
  font-family: 'GilroyLight';
}


.counter{
    font-size:700%;
    font-weight: 600;
    color: white;
    margin-top: 1%;
    font-family: 'GilroyBlackItalic';
}

.timeText{
    font-size:200%;
    font-weight: 600;
    color: white;
    margin-top: 1%;
    font-family: 'GilroyLight';
}
.X{
    font-size:300%;
    font-weight: 600;
    color: white;
    margin-right: 1%;
    font-family: 'GilroyBlackItalic';
}
.next_pusk{
    border: 2px solid white;
    border-radius: 20px;
    padding: 10px;
}

@font-face {
    font-family: 'GilroyLight'; 
    src:url('../fonts/Gilroy-Light.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }


.preTimeText{
    font-size:140%;
    font-weight: 600;
    color: white;
    margin-top: 1%;
    font-family: 'GilroyLight';
}

.menu_jet{
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; 
    font-size:200%;
    font-weight: 1000;
    position: fixed;
    bottom: 0;
    z-index: 999;
    
} 


.menu_rotate{
    color: white;
    font-family: 'GilroyBlackItalic'; 
    font-weight: 600;
  }
  
  .progress-bar {
    margin-top: 20px;
    width: 290px;
    height: 20px;
    border: 2px solid white;
    position: relative;
    border-radius: 20px;
    padding: 5px;
  }
  
  .progress-bar-fill {
    border-radius: 20px;
    height: 70%;
    background-image: linear-gradient(to right, #fcc40a, #fc930a);
    position: absolute;
    top: 0;
    left: 0;
    bottom: 1;
    animation: fillAnimation 60s linear forwards;
    margin: 5px;
  }
  
  
  @keyframes fillAnimation {
    0% { width: 5%; }
    100% { width: 95%; }
  }



  .div_kefs{
    width: 80%;
  }