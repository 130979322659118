*{
  margin: 0;
  padding: 0;
}

html, body, #root{
  height: 100%;
  margin:0;
  padding: 0;
}

.resultFlip{
  font-size:200%;
  font-weight: 600;
  color: white;
  font-family: 'GilroyLightItalic';
}


.CoinFlipBack{
  background-image: url('../images/background.gif');
  background-size: cover;
  width: 100%;
  height: 100vh;  
  display: flex ;
  flex-direction: column ; 
  align-items: center ; 
}



/*
.coinFlip{
  width: 400px;
  height: 400px;
}

.animation{
  width: 400px;
  height: 400px;
}


.menu{
  font-family: 'GilroyBlackItalic'; 
  font-size:200%;
  font-weight: 600;
  position: absolute;
  bottom: 90px;
}

.buttonFlip{
  width: 13%;
}



@font-face {
    font-family: 'GilroyBoldItalic'; 
    src: url('../fonts/Gilroy-BoldItalic.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }
@font-face {
    font-family: 'GilroyBlackItalic'; 
    src:url('../fonts/Gilroy-BlackItalic.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'GilroyLightItalic'; 
    src:url('../fonts/Gilroy-LightItalic.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }

@media screen and (max-width: 800px) {

    .coinFlip{
      width: 300px;
      height: 300px;
    }
    .animation{
      width: 300px;
      height: 300px;
    }
    .buttonFlip{      
      width: 40%;
    }
    .resultFlip{
      font-size:200%;
      font-weight: 600;
      color: white;
      margin-top: 30px;
      margin-bottom: 25px;
      font-family: 'GilroyLightItalic';
  }
  .menu{
    font-family: 'GilroyBlackItalic'; 
    font-size:200%;
    font-weight: 600;
    position: absolute; 
    bottom: 130px;
  }

  }


  @media screen and (max-height: 700px) {
    .coinFlip{
      width: 200px;
      height: 200px;
    }
    .animation{
       width: 60%;
       height: 28%;
    }
    .buttonFlip{      
      width: 35%;
    }
    .resultFlip{
      font-size:200%;
      font-weight: 600;
      color: white;
      margin-top: 30px;
      margin-bottom: 25px;
      font-family: 'GilroyLightItalic';
  }
  .menu{
    font-family: 'GilroyBlackItalic'; 
    font-size:200%;
    font-weight: 600;
    position: absolute; 
    bottom: 130px;
  }
  }



  @media screen and (min-width: 800px) and (max-width: 1400px) {
    .coinFlip{
        width: 400px;
        height: 400px;
    }
    .animation{
       width: 40%;
       height: 40%;
    }
    .buttonFlip{
      width: 30%;
    }
    .menu{
      font-family: 'GilroyBlackItalic'; 
      font-size:200%;
      font-weight: 600;
      position: absolute; 
      bottom: 80px;
    }
  }

*/
  .div_canvas{
    
  }
.result_flip{
  font-size:30px;
  font-weight:bolder;
  color: white;
  font-family: 'GilroyLightItalic';
  opacity: 0; /* Начальное значение прозрачности */
  animation: fadeIn 2.5s ease-in-out forwards; /* Анимация */
  position: absolute;
  margin-top: 50px;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(0.8);
  }
}

.resultFlip.fadeOut {
  animation: fadeOut 1.5s ease-in-out forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: scale(0.8);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}


  canvas{
    height: 450px !important;
    width: 300px !important;
  }


  .span_button_flip{
    color: white;
    font-family: 'GilroyBlackItalic'; 
    font-size:18px;
    font-weight: 1000;
    margin-left: 0px;
  }


  .coin_button_flip{
  width: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 30px;
  text-decoration: none;
  background: linear-gradient(180deg, #fa8874 0%, #da2525 100%);
  border-radius: 50px;
  margin: 10px 0px 20px 0px;
  transition: transform 0.3s, box-shadow 0.3s;
  box-shadow: 0px 5px 0px #b41212, /* Нижняя тень, создающая эффект приподнятости */
              0px 8px 10px rgba(0, 0, 0, 0.5); /* Дополнительная тень для глубины */
  text-align: center; /* Выровнять текст по горизонтали */
  
  }

  .coin_button_back{
    width: 70px;
    display: inline-block;
    flex-direction: column;
    align-items: center;
    padding: 10px 30px;
    text-decoration: none;
    background: linear-gradient(180deg, #fa8874 0%, #da2525 100%);
    border-radius: 50px;
    margin: 10px 0px 20px 0px;
    transition: transform 0.3s, box-shadow 0.3s;
    box-shadow: 0px 5px 0px #b41212, /* Нижняя тень, создающая эффект приподнятости */
                0px 8px 10px rgba(0, 0, 0, 0.5); /* Дополнительная тень для глубины */
    text-align: center; /* Выровнять текст по горизонтали */
  }


  .coin_button_flip:hover{
    background: linear-gradient(180deg, #fa8874 0%, #da2525 100%);
    transform: translateY(-5px); /* Поднятие кнопки вверх при наведении */
    box-shadow: 0px 8px 0px #b41212, /* Изменение нижней тени для создания эффекта высоты */
                0px 10px 15px rgba(0, 0, 0, 0.5);
  }

  .coin_button_flip:active {
    box-shadow: 0px 2px 0px #b41212, /* Тень уменьшается при нажатии */
                0px 6px 10px rgba(0, 0, 0, 0.3); /* Уменьшение интенсивности тени */
    transform: translateY(3px); /* Сдвиг вниз при нажатии */
  }