.app {
  flex-direction: column; 
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-image: url(../images/skulls/baack.jpg);
  background-repeat: no-repeat;
  background-position:center;
  background-size: cover;
  
}

.button-container {
  margin-top: 400px;
  display: flex;
  gap: 20px;
  flex-direction: column; 
  position: fixed;
}

.button {
  background-color: #ff4d4d;
  color: white;
  padding: 15px 30px;
  font-size: 18px;
  border: none;
  border-radius: 5px;
  box-shadow: 0 5px 10px rgba(255, 0, 0, 0.5);
  cursor: pointer;
  transition: all 0.3s ease;
}

.button:hover {
  transform: translateY(-5px);
  background-color: #ff6666;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background: white;
  padding: 20px;
  border-radius: 10px;
  position: relative;
  width: 300px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  flex-direction: column;
  
}



.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.close-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.modal-body {
  margin-top: 20px;
  text-align: center;
  width: 280px;  
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
}

.game_hash_modal_window{
  color: rgb(241, 86, 86);
  font-size: 20px;
  margin-left: 80px;

}
.modal-body input {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
}

.modal-footer {
  margin-top: 20px;
  text-align: center;
}

.modal-footer .button {
  width: 300px;
  box-shadow: 0 3px 6px rgba(255, 0, 0, 0.5);
  
}

.input_modal{
  width: 100px;
}

.result_skull{
  color: black;
  font-family: 'GilroyBlackItalic'; 
  font-size:30px;
  font-weight: 1000;
  margin-bottom: 20px;
}


.skull{
  height: 100px;
  width: 100px;
  margin-bottom: 20px;
  transition: transform 0.5s ease;
  position: relative;

}

.div_skulls{
  display: flex;
}


.animate_left {
  animation: randomMove_left 2.5s infinite;
}

.animate_right {
  animation: randomMove_right 2.5s infinite;
}

.animate_center {
  animation: randomMove_center 2.5s infinite;
}

@keyframes randomMove_left {
  0% {
      transform: translate(0, 0);
  }

  25% {
      transform: translate(200px, -20px);
  }
  50% {
      transform: translate(100px, -20px);
  }
  75% {
      transform: translate(200px, -20px);
  }
  100% {
      transform: translate(0px, 0px);
  }
}

@keyframes randomMove_center {
  0% {
      transform: translate(0, 0);
  }

  25% {
      transform: translate(-100px, -20px);
  }
  50% {
      transform: translate(100px, -20px);
  }
  75% {
      transform: translate(0px, -20px);
  }
  100% {
      transform: translate(100px, 0px);
  }
}


@keyframes randomMove_right {
  0% {
      transform: translate(0, 0);
  }

  25% {
      transform: translate(-100px, -20px);
  }
  50% {
      transform: translate(-200px, -20px);
  }
  75% {
      transform: translate(-200px, -20px);
  }
  100% {
      transform: translate(-100px, 0px);
  }
}



.raise {
  transform: translateY(-20px);
}

.diamond{
  width: 50px;
  height: 50px;
  margin-bottom: 20px;
}


.div_skull{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.display_none{
  display: none;
}

.skull_center{
  width: 50px;
  height: 50px;
  margin-left: 125px;

}

.div_result{
  margin-bottom: 30px;
}


.span_button_link{
  color: white;
  font-family: 'GilroyBlackItalic'; 
  font-size:18px;
  font-weight: 1000;
  
}

.skulls_button_link {
  width: 70px;
  display: inline-block;
  padding: 10px 30px;
  text-decoration: none;
  background: linear-gradient(180deg, #fa8874 0%, #da2525 100%);
  border-radius: 50px;
  transition: transform 0.3s, box-shadow 0.3s;
  box-shadow: 0px 5px 0px #b41212, /* Нижняя тень, создающая эффект приподнятости */
              0px 8px 10px rgba(0, 0, 0, 0.5); /* Дополнительная тень для глубины */
  margin: 10px 0px 20px 0px;
  text-align: center; /* Выровнять текст по горизонтали */
}

.skulls_button_link:hover {
  background: linear-gradient(180deg, #fa8874 0%, #da2525 100%);
  transform: translateY(-5px); /* Поднятие кнопки вверх при наведении */
  box-shadow: 0px 8px 0px #b41212, /* Изменение нижней тени для создания эффекта высоты */
              0px 10px 15px rgba(0, 0, 0, 0.5);
}

.skulls_button_link:active {
  box-shadow: 0px 2px 0px #116920, /* Тень уменьшается при нажатии */
              0px 6px 10px rgba(0, 0, 0, 0.3); /* Уменьшение интенсивности тени */
  transform: translateY(3px); /* Сдвиг вниз при нажатии */
}