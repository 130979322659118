

.mainModel{
    background-image: url('../images/main/back.gif');
    width: 100%;
    height: 100%;  
    display: flex; 
    flex-wrap: wrap;
    justify-content: center; 
    align-items: center; 
    background-position: center; /* Центрируем фон */
    background-repeat: no-repeat;
    background-size:cover;
}


.container_link{
    display: flex; 
    flex-wrap: wrap;
    justify-content: center; 
    align-items: center;
    width: 400px;
}  



.img_link{
    width: 160px;
    height: 120px;
    margin: 10px;
    border-radius: 40px;
    box-shadow: 0 0 10px 5px rgba(255, 255, 255, 0.5);
    background-size:cover;
    background-position: center; /* Центрируем фон */
    background-repeat: no-repeat;
}

.background_image_lacky{
    background-image: url('../images/main/lacky.png');
}

.background_image_coinflip{
    background-image: url('../images/main/coin_flip.png');
}

.background_image_mines{
    background-image: url('../images/main/mines.png');
    
}

.background_image_skulls{
    background-image: url('../images/main/skulls.png');
}



.link{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
/*
.button_link{
    background-color: #1C8D30;
    width: 120px;
    height: 35px;
    border-radius: 20px;
    margin-top: 10px;
    border: 2px solid black;
    box-shadow: 0px 5px 1px 1px #1C8D30, inset 0px -6px 0px rgba(255, 255, 255, 0.5);
}
*/

@font-face {
    font-family: 'EvanstonTavern-1846'; 
    src:url('../fonts/EvanstonTavern-1846Medium.woff') format('woff');
    font-weight:normal;
    font-style:normal;
  }

.span_button{
    color: white;
    font-family:'EvanstonTavern-1846'; 
    font-size:17px;
    font-weight: 1000;
}

.button_link {
    display: inline-block;
    padding: 10px 30px;
    text-decoration: none;
    background: linear-gradient(180deg, #32a84b 0%, #1c8d30 100%);
    border-radius: 50px;
    transition: transform 0.3s;
    box-shadow: 0px 5px 0px #116920, /* Нижняя тень, создающая эффект приподнятости */
                0px 8px 10px rgba(0, 0, 0, 0.5); /* Дополнительная тень для глубины */
    margin: 10px 0px 20px 0px;
}

.button_link:hover {
    background: linear-gradient(180deg, #1c8d30 0%, #32a84b 100%);
    transform: translateY(-5px); /* Поднятие кнопки вверх при наведении */
    box-shadow: 0px 8px 0px #116920, /* Изменение нижней тени для создания эффекта высоты */
                0px 10px 15px rgba(0, 0, 0, 0.5);
}

.button_link:active {
    box-shadow: 0px 2px 0px #116920, /* Тень уменьшается при нажатии */
                0px 6px 10px rgba(0, 0, 0, 0.3); /* Уменьшение интенсивности тени */
    transform: translateY(3px); /* Сдвиг вниз при нажатии */
}

.span_under_image{
    color: white;
    font-family:'EvanstonTavern-1846'; 
    font-size:20px;
    font-weight: 900;
    margin-top: 5px;
}