/*
.minesMain{
    background-image: url('../images/maines/background.gif');
    width: 100%;
    height: 100%;  
    display: flex; 
  flex-direction: column; 
  justify-content: center; 
  align-items: center; 
  background-repeat: no-repeat;
  background-size: cover;
}

.menu{
    font-family: 'GilroyBlackItalic'; 
    font-size:200%;
    font-weight: 600;
    position: absolute;
    bottom: 10px;
  }

.buttonMines{
  position: absolute;
  margin-top: 400px;
  width: 200px;
}

.minesUp{
  position: absolute;
  margin-top: -400px;
}
.timestartUp{
  font-family: 'GilroyLightItalic'; 
  color: white;
  font-size: 20px;
}

.timestartDown{
  font-family: 'GilroyBlackItalic'; 
  color: white;
  font-size: 20px;
}

.timedivstart{
  display: flex;
  flex-direction: column; 
  justify-content: center; 
  align-items: center; 
}

.menu_back{
  font-family: 'GilroyBlackItalic'; 
  font-size:100%;
  font-weight: 600;

}




.menu_rotate{
  color: white;
  font-family: 'GilroyBlackItalic'; 
  font-weight: 600;
}

.div_quantity_mines{
  display: flex; 
  
}

.div_quantity_mines_minus{
  margin-top: 30px;
  border-radius: 60px;
  background-color: white;
  height: 50px;
  width: 50px;
  display: flex; 
  flex-direction: column; 
  justify-content: center; 
  align-items: center; 

}
.div_quantity_mines_plus{
  margin-top: 30px;
  border-radius: 60px;
  background-color: white;
  height: 50px;
  width: 50px;

  display: flex; 
  flex-direction: column; 
  justify-content: center; 
  align-items: center; 
}

.div_quantity_mines_text{
  font-weight:bolder;
  font-size: 35px;
  color: rgb(rgb(79, 50, 243), green, blue)
}
.margin_minus{
  margin-bottom: 35px;
}
.div_quantity_text{
  color: white;
  font-family: 'GilroyBlackItalic'; 
  font-weight: 600;
  font-size: 35px;
}
.div_quantity{
  margin-top: 30px;
  width: 80px;
  height: 50px;
  display: flex; 
  flex-direction: column; 
  justify-content: center; 
  align-items: center;
}
*/

.main_pole{
  width: 300px;
  height: 300px;
  background-color:rgb(35, 143, 169);
  border-radius: 10px;
  display: flex;
  flex-direction: column; 
  justify-content: center; 
  align-items: center; 
  padding: 15px;
 
}

.box{
  border: 1px solid black;
  width: 50px;
  height: 50px;
  border-radius: 10px;
  margin: 5px;

}

.box_img{
width: 100%;
border: 1px solid red;

}

.line_pole{
  display: flex;
}

.container{
  display: flex;
  flex-direction: column; 
  justify-content: center; 
  align-items: center;  
  height: 100%;
  background-image: url('../images/main/back.gif');
}

.menu_quantity_mines{
    /*margin-top: 50px;*/
    margin-top: 30px;
  background-color:rgb(42, 58, 74);
  width: 330px;
  height: 80px;
  border-radius: 10px;
  display: flex;
  justify-content: space-around; 
  align-items: center;
}

.div_minus:hover, .div_plus:hover {
  background-color: #294B6D; /* Цвет при наведении */
  transform: scale(1.05); /* Легкое увеличение */
}


.div_minus{
  width: 60px;
  height: 60px;
  background-color:rgb(35, 143, 169);
  border-radius: 15px;  
  transition: background-color 0.3s ease, transform 0.3s ease;
  display: inline-block;
  cursor: pointer;
}

.span_minus{
  margin-left: 20px;  
  font-size:40px;
  font-weight: 1000;
  color: white;
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif
}

.span_plus{  
  margin-left: 15px;  
  font-size:40px;
  font-weight: 1000;
  color: white;
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif
}

.div_plus{
  width: 60px;
  height: 60px;
  background-color:rgb(35, 143, 169);
  border-radius: 15px;  

  transition: background-color 0.3s ease, transform 0.3s ease;
  display: inline-block;
  cursor: pointer;
}

.button_signal{
  display: flex;
  flex-direction: column; 
  justify-content: center; 
  align-items: center; 
  background-color:rgb(35, 143, 169); 
  /*margin-top: 50px;*/
  margin-top: 30px;
  width: 330px;
  height: 80px;
  border-radius: 10px;
  box-shadow: 0 6px #294B6D;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  transform-origin:bottom; /* Увеличение будет происходить вниз */
}


.button_signal:hover {
  transform: scaleY(1.05); /* Увеличение высоты на 20% */
  box-shadow: 0 6px #294B6D; /* Более глубокая тень при наведении */
  background-color: #294B6D; /* Цвет при наведении */
}

.span_button_signal{
  font-size:30px;
  font-weight: 1000;
  color: white;
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif
}


.span_quantityMines{
  font-size:30px;
  font-weight: 1000;
  color: white;
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif
}


.video{
  width: 300px;
  height: 300px;
  border-radius: 15px;
  transition: opacity 0.5s ease-in-out;
}

.initial_image{
  width: 300px;
  height: 300px;
}


.menu_mines{
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;  
  font-size:200%;
  font-weight: 1000;
  position: fixed;
  bottom: 0;
  z-index: 999;
  color:rgb(35, 143, 169);
} 


.menu_rotate_mines{
  color: white;
  font-family:'EvanstonTavern-1846';  
  font-weight: 600;
}